
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({
  name: "RouteHeader",
})
export default class RouteHeader extends Vue {
  get shouldShowHeader(): boolean {
    const path = this.$route.path;
    const segments = path.split("/").filter((segment) => segment);
    return segments.length >= 2 && !this.$route.meta?.hideMobileHeader;
  }

  get currentRouteName(): string {
    return this.$t("routes." + this.$route.name).toString() || "";
  }

  get isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  goBack(): void {
    const segments = this.$route.path.split("/").filter((segment) => segment);
    if (segments.length >= 1) {
      this.$router.push(`/${segments[0]}`).catch(() => {});
    }
  }
}
