
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "Landingpage",
})
export default class FrontPageHeader extends Vue {
  navigateHome(): void {
    this.$router.push("/").catch(() => {});
  }
}
