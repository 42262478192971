export default {
  welcome: "Bienvenido",
  months: {
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
  },
  days: {
    sunday: "Dom",
    monday: "Lun",
    tuesday: "Mar",
    wednesday: "Mié",
    thursday: "Jue",
    friday: "Vie",
    saturday: "Sáb",
  },
  agendaTitle: "Agenda",
  update: {
    title: "Actualizar",
  },
  subscriptionWarnings: {
    message1:
      "¡Hola! Has probado el programa gratis durante 14 días. Esperamos que te resulte útil.",
    message2:
      "Si deseas seguir usando el programa, compra una suscripción en 'Configuración' -> 'Suscripción'",
    message3: "Si tienes alguna pregunta, contáctanos. ¡Gracias!",
  },
  buttons: {
    gotoSubscription: "Ir a Suscripción",
    close: "Cerrar",
    ok: "Ok",
    save: "Guardar",
    delete: "Eliminar",
    cancel: "Cancelar",
    edit: "Editar",
    create: "Crear",
  },

  noAppointmentsToday: "¡No hay citas hoy!",
  readNotes: "Leer notas",
  readCustomerComment: "Leer comentario del cliente",
  dialog: {
    customerComment: "Comentario del Cliente",
    notes: "Notas",
    confirmDeleteService: "¿Estás seguro de que deseas eliminar este servicio?",
    deleteServiceHeader: "Eliminar Servicio",
    cancel: "Cancelar",
    save: "Guardar",
    confirm: "OK",
  },
  messages: {
    updated: "Actualizado.",
    categoryChanged: "La categoría ha sido cambiada.",
    categoryOrderChanged: "El orden de la categoría ha sido cambiado.",
    serviceOrderChanged: "El orden de la categoría ha sido cambiado.",
    categoryUpdated: "La categoría ha sido actualizada.",
    newCategoryAdded: "Nueva categoría agregada.",
    categoryDeleted: "La categoría ha sido eliminada.",
    newServiceAdded: "Nueva técnica agregada.",
    couldNotAddService: "No se pudo agregar una nueva técnica.",
    serviceDeleted: "La técnica ha sido eliminada.",
    serviceUpdated: "La técnica ha sido actualizada.",
  },
  staff: "Personal",
  date: "Fecha",
  service: "Servicio",
  price: "Precio",
  customerAccount: "Cuenta del Cliente",
  automaticAssignExistingCustomerAccount:
    "Asignar automáticamente la cuenta del cliente existente a nuevas reservas en función del campo.",
  createAssignCustomerAccountAutomatically:
    "Crear/Asignar cuenta de cliente automáticamente al reservar",
  customerAccountWillBeAssignedBasedOnPhoneNumber:
    "La cuenta de cliente se asignará a la existente en función del número de teléfono.",
  newCustomField: "Nuevo Campo Personalizado",
  selectFieldsCustomerFillsWhenBooking:
    "Selecciona los campos que el cliente debe completar al reservar",
  optional: "Opcional",
  customFields: "Campos Personalizados",
  add: "Añadir",
  link: "Enlace",
  yourBookingLinkWillBe: "Tu enlace de reserva será {link}",
  connectSystemWithCommonBookingLink:
    "Conecta el sistema con un enlace de reserva común",
  yourCommonBookingLinkWillBe: "Tu enlace de reserva común será {link}",
  bookingID: "ID de Reserva",
  commonID: "ID Común",
  locationName: "Nombre de Ubicación",
  showInformationBox: "Mostrar Cuadro de Información",
  shouldInformationBoxBeDisplayed:
    "¿Debe mostrarse el cuadro de información en la página de reserva?",
  informationBox: "Cuadro de Información",
  informationForCustomerOnBookingPage:
    "Información para el cliente en la página principal de reserva",
  cancellationInformation: "Información de Cancelación",
  customizeInformationCustomerSeesWhenCancelling:
    "Personaliza la información que ve el cliente al cancelar",
  receiveEmailOnAppointment: "Recibir Correo Electrónico sobre la Cita",
  doYouWantToReceiveEmailOnNewAppointment:
    "¿Deseas recibir un correo electrónico cuando se reciba una nueva cita?",
  whichEmailAddressToReceive:
    "¿A qué dirección de correo electrónico se debe enviar el correo al reservar?",
  receiveSMSOnAppointment: "Recibir SMS sobre la Cita",
  doYouWantToReceiveSMSOnNewAppointment:
    "¿Deseas recibir un SMS cuando se reciba una nueva cita?",
  whichPhoneNumberToReceiveSMS:
    "¿A qué número de teléfono se debe enviar el SMS al reservar?",
  ssn: "Número de Seguro Social",
  birthdate: "Fecha de Nacimiento",
  gender: "Género",
  city: "Ciudad",
  postalCode: "Código Postal",
  pause: "Pausa",
  event: "Evento",
  from: "Desde",
  to: "Hasta",
  durationMismatch:
    "La duración de la reserva difiere de la duración del servicio.",
  priceMismatch: "El precio de la reserva difiere del precio del servicio.",
  cancel: "Cancelar",
  create: "Crear",
  confirm: "Confirmar",
  invalidTimeFormat: "Formato de tiempo inválido",
  enterCustomerName: "Debes ingresar un nombre de cliente.",
  selectService: "Debes seleccionar un servicio.",
  editEndTimeWithoutStart:
    "Intento de editar la hora de finalización sin establecer la hora de inicio.",
  minutesAbbreviation: "min",
  currency: "$",
  creationCancelled: "Creación cancelada.",
  customEvent: "Evento personalizado",
  monthlyEarningsTitle: "Ganancias de {month}",
  totalCustomers: "Total de Clientes",
  earningsTrend: "Tendencia de Ganancias",
  servicesBooked: "Servicios Reservados",
  moneyEarnedIn: "Ganancias en {month}",
  appointmentsIn: "Citas en {month}",
  appointmentBookingLinkTitle: "Enlace de Reserva de Cita",
  appointmentBookingLinkDescription:
    "Usa el enlace a continuación para reservar una cita.",
  bookingLinkLabel: "Enlace de Reserva",
  copyLinkButton: "Copiar Enlace",
  linkCopiedMessage: "¡Enlace copiado al portapapeles!",
  closePreview: "Cerrar Vista Previa",
  previewBookingPage: "Vista Previa de la Página de Reservas",
  contactUs: "Contáctanos",
  sidebar: {
    home: "Inicio",
    customers: "Clientes",
    calendar: "Calendario",
    settings: "Configuración",
    appointments: "Citas",
    overview: "Resumen",
    payments: "Pagos",
    invoices: "Facturas",
    statistics: "Estadísticas",
    export: "Exportar",
    contactSupport: "Contactar Soporte",
    log: "Log",
    notifications: "Notificaciones",
    journal: "Diario",
  },
  name: "Nombre",
  email: "Email",
  phoneNumber: "Teléfono",
  address: "Dirección",
  postalCodeCity: "Código Postal/Ciudad",
  edit: "Editar",
  created: "Creado",
  deleteSelectedCustomers: "Eliminar clientes seleccionados",
  search: "Buscar",
  searchForCustomer: "Buscar cliente",
  noCustomersYet: "No tienes clientes aún",
  noSearchResults: "Sin resultados de búsqueda",
  customerWithoutName: "Cliente sin nombre",
  confirmDeleteCustomers:
    "¿Estás seguro de que deseas eliminar {count} clientes?",
  deleteCustomerHeader: "¿Eliminar cliente(s)?",
  customersDeleted: "Se han eliminado {count} clientes",
  customer: {
    notFound: "Cliente no encontrado.",
    name: "Nombre",
    email: "Correo Electrónico",
    phoneNumber: "Número de Teléfono",
    address: "Dirección",
    postalCodeCity: "Código Postal/Ciudad",
    edit: "Editar",
    created: "Creado",
    deleteSelectedCustomers: "Eliminar clientes seleccionados",
    search: "Buscar",
    searchForCustomer: "Buscar cliente",
    noCustomersYet: "No tienes clientes aún",
    noSearchResults: "Sin resultados de búsqueda",
    customerWithoutName: "Cliente sin nombre",
    confirmDeleteCustomers:
      "¿Estás seguro de que deseas eliminar {count} clientes?",
    deleteCustomerHeader: "¿Eliminar cliente(s)?",
    customersDeleted: "Se han eliminado {count} clientes",
  },
  calendar: {
    week: "Semana",
    month: "Mes",
    day: "Día",
    sideBySide: "Lado a lado",
    threeFourDays: "3-4 días",
    heading: "Calendario",
  },
  appointment: {
    deleteSelectedAppointments: "Eliminar citas seleccionadas",
    filter: "Filtrar",
    all: "Todos",
    showMoreCustomerInfo: "Mostrar más información del cliente",
    noAppointments: "No hay citas",
    select: "Seleccionar",
    loading: "Cargando... Por favor espera...",
    status: {
      ny: "Nuevo",
      invoiceCreated: "Factura Creada",
      invoiceSent: "Factura Enviada",
      invoicePaid: "Factura Pagada",
      ferdig: "Completado",
    },
    comment: "Comentario",
    adminNote: "Nota",
    created: "Creado",
    number: "Número",
    service: "Servicio",
    time: "Hora",
    customer: "Cliente",
    appointmentStatus: "Estado",
    createAppointment: "Crear Cita",
    editAppointment: "Editar Cita",
  },
  companyInfo: {
    companyName: "Nombre de la Empresa",
    companyNameDescription:
      "El nombre de la empresa tal como se muestra al cliente",
    companyAddress: "Dirección de la Empresa",
    companyAddressDescription:
      "La dirección de la empresa tal como se muestra al cliente",
    phoneNumber: "Número de Teléfono",
    phoneNumberDescription:
      "El número de teléfono de la empresa tal como se muestra al cliente",
    email: "Correo Electrónico",
    emailDescription:
      "El correo electrónico de la empresa tal como se muestra al cliente",
    companyLogo: "Logotipo de la Empresa",
    companyLogoDescription:
      "El logotipo de la empresa tal como se muestra al cliente",
  },
  snackbar: {
    imageTooLarge: "La imagen debe ser menor de 200 KB",
    invalidImageFormat: "La imagen debe estar en formato JPG o PNG",
    enterServiceName: "Se debe proporcionar el nombre del servicio.",
    negativeDiscountPrice: "El precio de descuento no puede ser negativo.",
    enterDuration: "Se debe proporcionar la duración en minutos.",
    durationMustBeNumber: "La duración en minutos debe ser un número.",
    priceMustBeNumber: "El precio debe ser un número.",
    negativePrice: "El precio no puede ser negativo.",
    invalidDuration: "Duración inválida.",
    durationExceedsLimit: "Un solo servicio no puede durar más de 12 horas.",
  },
  account: {
    registerNewAccount: "Registrar Nueva Cuenta",
    editAccount: "Editar Cuenta",
    allAccounts: "Todas las Cuentas",
    lastSignIn: "Último acceso: {date}",
    created: "Creado: {date}",
    yourAccount: "Tu Cuenta",
  },
  role: {
    administrator: "Administrador",
    user: "Usuario",
  },
  logout: "Cerrar sesión",
  changePassword: "Cambiar Contraseña",
  logoutConfirm: "¿Deseas cerrar sesión?",
  forgotPassword: "¿Deseas enviar un enlace de restablecimiento a {email}?",
  passwordResetEmailSent:
    "Se ha enviado un correo electrónico para restablecer la contraseña.",
  subscription: {
    yourPlan: "Tu Plan",
    nextPayment: "Próximo Pago",
    previousPayment: "Pago Anterior",
    paymentInformation: "Información de Pago",
    cardEnding: "Visa terminada en {card}",
    noTrial:
      "Tu período de prueba ha expirado. Compra una suscripción para continuar usando el programa.",
    paymentIssue: "Hay un problema con tu pago. Contáctanos.",
    trialDaysLeft: "Te quedan {days} días de prueba gratuita.",
    cancelledWithDate:
      "La suscripción no se renovará el próximo mes. Está activa hasta {date}.",
    cancelledWithoutDate:
      "La suscripción no se renovará el próximo mes. Está activa hasta fin de mes.",
    active: "Tienes una suscripción activa.",
    yearlyWithSMS:
      "Tienes una suscripción válida hasta {date} con SMS incluido.",
    monthlyCost:
      "La suscripción cuesta 250kr al mes, sin período de permanencia. Cancela en cualquier momento. Al cancelar, la suscripción durará hasta la próxima fecha de pago.",
    automaticPayment:
      "El pago es automático cada mes. Si prefieres una factura, no dudes en pedirla.",
    smsCost:
      "Si has elegido enviar recibos/recordatorios por SMS, o notificación por SMS al reservar, cuesta 1kr por SMS y se añadirá automáticamente al pago.",
    purchase: "Comprar suscripción",
    reactivate: "Reactivar suscripción",
    cancel: "Cancelar suscripción",
    cancelSubscriptionConfirm:
      "¿Estás seguro de que deseas cancelar tu suscripción?",
    subscription: "Suscripción",
  },
  createCustomer: "Crear Cliente",
  editCustomer: "Editar Cliente",
  lastName: "Apellido",
  dateOfBirth: "Fecha de Nacimiento",
  male: "Masculino",
  female: "Femenino",
  selectInsurance: "Seleccionar Seguro",
  noInsuranceData: "Los seguros pueden añadirse en configuración.",
  customerUpdated: "Cliente actualizado.",
  customerCreated: "Cliente creado.",
  settings: {
    general: "General",
    companyInfo: "Información de la Empresa",
    subscription: "Suscripción",
    accounts: "Cuentas",
    services: "Servicios",
    extraServices: "Servicios Extras",
    categories: "Categorías",
    resources: "Recursos",
    options: "Opciones",
    staff: "Personal",
    calendar: "Calendario",
    functions: "Funciones",
    newsletter: "Boletín",
    customerAccounts: "Cuentas de Cliente",
    invoicesAndPayments: "Facturas y Pagos",
    booking: "Reserva",
    information: "Información",
    alerts: "Alertas",
    bookingSide: "Página de Reserva",
    customerInformation: "Información del Cliente",
    customerAccount: "Cuentas de Cliente",
    workingHours: "Horario Laboral",
    friday: "Días de Vacaciones",
    googleCalendar: "Google Calendar",
    overview: "Resumen",
    customFields: "Campos Personalizados",
    giftCards: "Tarjetas de Regalo",
    customerFollowUp: "Seguimiento de Cliente",
    cashRegisterSystem: "Sistema de Caja",
    multipleLocations: "Múltiples Ubicaciones",
    system: "Sistema",
    auditLog: "Registro de Auditoría",
    staffUpdated: "Personal actualizado.",
    staffDeleted: "Personal eliminado.",
  },
  resourceName: "Nombre del Recurso",
  description: "Descripción",
  save: "Guardar",
  quantity: "Cantidad",
  multipleServicesQuestion:
    "¿Debe el cliente poder elegir múltiples servicios en una sola reserva?",
  fields: "Campos",
  showServicePrice: "Mostrar precio de servicios",
  serviceName: "Nombre del Servicio",
  sort: "Ordenar",
  duration: "Duración",
  category: "Categoría",
  extraServices: "Servicios Extras",
  boolean: {
    yes: "Sí",
    no: "No",
    on: "Encendido",
    off: "Apagado",
  },
  categories: "Categorías",
  services: "Servicios",
  staffMembersOverview: {
    minOneStaffRequired:
      "Debes tener al menos un miembro del personal para que el programa funcione.",
    confirmDeleteStaff: "¿Deseas eliminar a este miembro del personal?",
    staff: "Personal",
    yourStaff: "Tu Personal",
    newStaff: "Nuevo Personal",
    firstName: "Nombre",
    lastName: "Apellido",
    emailAddress: "Correo Electrónico",
    phoneNumber: "Número de Teléfono",
    created: "Creado",
    delete: "Eliminar",
    lastLoggedIn: "Último Acceso",
    editStaff: "Editar Personal",
  },
  staffMemberDetails: {
    firstName: "Nombre",
    lastName: "Apellido",
    phoneNumber: "Número de Teléfono",
    emailAddress: "Correo Electrónico",
    colorForCalendar: "Color para el Calendario",
    selectColor:
      "Selecciona 'Color' en 'Calendario' -> 'Mostrar personal en evento' para elegir un color para el personal.",
    save: "Guardar",
  },
  staffMemberGoogleCalendar: {
    confirmRemoveSync:
      "¿Estás seguro de que deseas eliminar la sincronización?",
    syncGoogleCalendar: "Sincronizar Google Calendar con Availify",
    start: "Iniciar",
    continue: "Continuar",
    removeSync: "Eliminar Sincronización",
  },
  vacationDays: {
    markDayAs: "Marcar día como:",
    available: "Disponible",
    notAvailable: "No Disponible",
  },
  workingHours: {
    timeRequired: "El tiempo es requerido",
    invalidFormat: "Formato inválido (HH:MM)",
    invalidTimeFormat:
      "No se pudo actualizar el horario de apertura. Verifica si has ingresado el formato correcto (HH:MM)",
    updated: "Horario de apertura actualizado.",
    availableOnThisDay: "Disponible en este día: {value}",
    openingHourFor: "Hora de apertura para {day}",
    closingHourFor: "Hora de cierre para {day}",
    breaks: "Pausas",
    start: "Inicio",
    end: "Fin",
    addBreak: "Añadir Pausa",
    save: "Guardar",
  },
  generalBookingSettings: {
    customerVerification: "Verificación de Cliente",
    customerVerificationDescription:
      "¿Qué debe verificar el cliente para reservar una cita?",
    cancellationModification: "Cancelación/Modificación de Citas",
    cancellationModificationDescription:
      "¿Debe el cliente poder cambiar/cancelar la cita?",
    minimumHoursBefore: "Horas mínimas antes de la cita",
    minimumHoursBeforeDescription:
      "Número de horas antes de la cita en que el cliente puede cancelar",
    remindersReceipts: "Recordatorios/Recibos para Clientes",
    remindersReceiptsDescription:
      "¿Cómo se deben enviar los recibos/recordatorios para la cita?",
    alwaysSendReceipt: "Enviar siempre recibo",
    alwaysSendReceiptDescription:
      "La casilla no necesita ser marcada por el cliente",
    alwaysSendReminder: "Enviar siempre recordatorio",
    alwaysSendReminderDescription:
      "La casilla no necesita ser marcada por el cliente",
    bookingRestrictions: "Restricciones de Reserva",
    bookingRestrictionsDescription:
      "¿Cuántos días antes puede un cliente reservar?",
    earlyBooking: "¿Con cuánta anticipación puede un cliente reservar?",
    earlyBookingDescription:
      "Número de horas antes de la cita que se puede reservar",
    noVerification: "Sin Verificación",
    email: "Correo Electrónico",
    sms: "SMS",
    auditVerificationChanged:
      "Configuración cambiada: 'Verificación de Cliente'",
    auditCancellationChanged:
      "Configuración cambiada: 'Cancelación/Modificación de Citas'",
    auditHoursToCancelChanged:
      "Configuración cambiada: 'Horas mínimas antes de la cancelación de cita' a {hours} horas",
    auditReceiptReminderChanged:
      "Configuración cambiada: 'Recordatorios/Recibos para Clientes'",
    auditMaxDaysChanged:
      "Configuración cambiada: 'Restricciones de Reserva' número de días que un cliente puede reservar antes a {days} días.",
    auditMinHoursChanged:
      "Configuración cambiada: 'Restricciones de Reserva' cuántas horas antes puede un cliente reservar {hours} horas.",
    emailRequired:
      "El campo de correo electrónico ha sido configurado como obligatorio porque la información se envía por correo electrónico.",
    phoneRequired:
      "El campo de número de teléfono ha sido configurado como obligatorio porque los recibos y recordatorios se envían por SMS.",
    days: "Días",
    hours: "Horas",
  },
  bookingDistribution: {
    timeDivision: "División de Tiempo",
    selectTimeSlots:
      "Selecciona los intervalos de tiempo que se pueden reservar.",
    confirmChange:
      "¿Estás seguro de que deseas cambiar la división de tiempo a {minutes} minutos?",
    changeTimeFormat: "Cambiar Formato de Tiempo",
    auditChange:
      "Cambio en la división de tiempo a {minutes} minutos para reservas",
  },
  // CalendarStyleSettings.vue
  hideDaysOutsideWorkingHours: "Ocultar días fuera del horario laboral",
  cannotActivateWithMultipleStaff:
    "No se puede activar con múltiples miembros del personal",
  events: "Eventos",
  showServiceNameInEvent: "Mostrar nombre del servicio en el evento",
  showStaffInEvent: "Mostrar personal en el evento",
  showTimeInEvent: "Mostrar hora en el evento",
  color: "Color",

  // EmailListSettings.vue
  newsletter: "Boletín",
  askCustomersToSignUpForNewsletter:
    "Pide a los clientes que se suscriban al boletín al reservar",
  subscribers: "Suscriptores",
  noSubscribersYet: "Aquí estará el resumen de todos los suscriptores",
  signedUp: "Registrado",
  loading: "Cargando... Por favor espera...",
  downloadExcel: "Descargar Excel (.xlsx)",
  exportAppointments: "Exportar todas las citas",
  eventsOverview: {
    message: "Mensaje",
    time: "Hora",
    comment: "Comentario",
    booking: "Reserva",
    receipt: "Recibo",
    reminder: "Recordatorio",
    seen: "Visto",
    new: "Nuevo",
    previous: "Anterior",
    noEvents: "No tienes eventos",
    guest: "Invitado",
    critical: "Crítico",
    info: "Info",
    warning: "Advertencia",
    customer: "Cliente",
    errorWithSmsSending:
      "Algo salió mal con el envío de SMS. La verificación por SMS se ha desactivado.",
    timeAddedToExistingCustomer:
      "La hora reservada se añadió automáticamente al cliente existente, pero había diferencias.",
    timeCancelled: "La hora reservada fue cancelada.",
    timeChanged: "La hora reservada fue cambiada.",
    newCustomer: "Nuevo cliente.",
    customerComment: "Comentario del Cliente",
    bookingCancelled: "Reserva cancelada",
    bookingChanged: "Reserva cambiada",
  },
  onboarding: {
    user: "Usuario",
    company: "Empresa",
    workingHours: "Horario Laboral",
    services: "Servicios",
    finish: "Finalizar",
    pickWorkingHours: "Seleccionar Horario Laboral",
    createServices: "Crear Servicios",
    yourUser: "Tu Usuario",
    yourCompany: "Tu Empresa",
  },
  auditOverview: {
    event: "Evento",
    time: "Hora",
    comment: "Comentario",
    noChanges: "Aquí estará el resumen de todos los cambios realizados",
    customer: "Cliente",
    booking: "Reserva",
    settings: "Configuración",
    staff: "Personal",
    track: "Seguimiento",
    service: "Servicio",
    readComment: "Leer comentario en el registro",
  },
  customerModal: {
    notes: "Notas",
    createdByCustomer: "Creado por el cliente a través de la reserva online",
    createdByStaff: "Creado por el personal",
    statistics: {
      appointmentsThisMonth: "Citas este mes",
      appointmentsOverall: "Citas en total",
      totalUsedForServicesThisMonth: "Total usado para servicios este mes",
      totalUsedForServicesOverall: "Total usado para servicios en total",
    },
    payments: "Pagos",
    newJournal: "Agregar nueva bitácora",
    buttons: {
      cancel: "Cancelar",
      save: "Guardar",
    },
    dialog: {
      unsavedChangesWarning:
        "Tienes cambios sin guardar en la bitácora. Si sales, se perderán los cambios.",
      exitConfirmation:
        "Tienes cambios sin guardar en la bitácora. ¿Estás seguro de que deseas salir?",
    },
    journal: "Bitácora",
    generalText: "Texto general",
  },
  nameIsRequired: "El nombre es obligatorio",
  amountMustBeOneOrHigher: "La cantidad debe ser 1 o mayor",
  resources: {
    deleteConfirmation: "¿Estás seguro de que deseas eliminar este recurso?",
    deleteHeader: "Eliminar Recurso",
    name: "Nombre",
    description: "Descripción",
    quantity: "Cantidad",
    createResource: "Crear Recurso",
    editResource: "Editar Recurso",
    nameDescription: "Nombre del recurso",
    descriptionDescription: "Descripción del recurso",
    quantityDescription: "Cantidad del recurso",
  },
  functions: {
    contactUs: "Contáctanos",
    customerLogin: "Acceso de Cliente",
    invoicesAndPayments: "Facturas y Pagos",
    customFields: "Campos Personalizados",
    newsletter: "Boletín",
    giftCards: "Tarjetas de Regalo",
    customerFollowUp: "Seguimiento de Cliente",
  },
  login: {
    fillEmailPassword: "Se deben completar el correo y la contraseña.",
    passwordsMustMatch: "Las contraseñas deben coincidir.",
    emailInUse: "Esta dirección de correo electrónico ya está en uso.",
    invalidEmail: "Dirección de correo electrónico inválida.",
    weakPassword: "La contraseña debe tener al menos 6 caracteres.",
    userNotFound: "Usuario no encontrado.",
    wrongEmailPassword: "Correo o contraseña incorrectos.",
    fillEmailForReset:
      "Por favor, completa tu correo para restablecer la contraseña.",
    sendNewPasswordTo: "¿Enviar nueva contraseña a {email}?",
    emailSent: "Se ha enviado un correo electrónico.",
    tryFree: "Prueba gratis por 14 días",
    repeatPassword: "Repetir contraseña",
    password: "Contraseña",
    register: "Registrarse",
    haveAccount: "¿Tienes cuenta?",
    loginHere: "Inicia sesión aquí",
    login: "Iniciar sesión",
    forgot: "¿Olvidaste?",
    next: "Siguiente",
    firstName: "Nombre",
    lastName: "Apellido",
    phoneNumber: "Número de Teléfono",
    phoneNumberRequired: "El número de teléfono debe tener 8 dígitos.",
    firstNameRequired: "El nombre es obligatorio.",
    lastNameRequired: "El apellido es obligatorio.",
    companyName: "Nombre de la Empresa",
    treatmentAdress: "Dirección de la Empresa",
    confirm: "Confirmar",
    continue: "Continuar",
  },
  systemsSettings: {
    selectLanguage: "Seleccionar Idioma",
    languageDescription:
      "Selecciona el idioma para el sistema, tanto para el panel de control como para el sitio de reservas.",
    language: "Idioma",
    timeFormat: "Formato de Tiempo",
    timeFormatDescription:
      "Selecciona el formato de tiempo para el sistema, tanto para el panel de control como para el sitio de reservas.",
    timeZone: "Zona de Tiempo",
    timeZoneDescription:
      "Selecciona la zona de tiempo para el sistema. Contacta con nosotros para cambiar.",
    defaultCountryCode: "Código de País por Defecto",
    defaultCountryCodeDescription:
      "Selecciona el código de país por defecto para el sistema. Esto se utilizará para establecer el código de país por defecto en la página de reservas y para determinar el código de país para SMS al personal.",
  },
  user: {
    updateFailed: "No se pudo actualizar el usuario.",
    smsVerificationError:
      "No se puede usar la verificación por SMS sin una suscripción.",
    bookingLinkExists:
      "Este nombre de empresa ya existe. ¿Es tu empresa? Contacta con nosotros.",
    loginError:
      "Algo salió mal con la conexión. Si este problema persiste, por favor contacta con nosotros.",
    imageUploaded: "La imagen ha sido subida.",
    imageUploadError:
      "La imagen es demasiado grande o tiene un formato incorrecto. Use JPG o PNG.",
  },
  hideInBooking: "Ocultar en reserva",
  discount: "Descuento",
  newPrice: "Nuevo precio",
  assignToAllStaff: "Asignar a todos los empleados",
  appointmentSummary: {
    fromOnlineBooking: "Desde reserva online",
    assignStaff: "Asignar personal",
    yourNotes: "Sus notas",
  },
  appointmentMenu: {
    moveAppointmentMessage:
      "Haz clic en un tiempo en el calendario para mover la cita.",
    confirmDeleteAppointment: "¿Estás seguro de que deseas eliminar esta cita?",
    deleteAppointmentHeader: "Delete Appointment",
    appointmentDeleted: "La cita ha sido eliminada.",
    edit: "Editar",
    delete: "Eliminar",
    findInCalendar: "Buscar en Calendario",
  },
  customerModalMessages: {
    customerUpdated: "Cliente actualizado.",
    customerUpdateFailed: "No se pudo actualizar el cliente.",
  },
  multipleServices: "Servicios múltiples",
  emailAddress: "Correo Electrónico",
  commitChart: {
    currentYear: "Ganado {year}",
    previousYear: "Ganado {year}",
  },
  calendarMessages: {
    moveAppointmentTo: "Mover cita a",
  },
  appointments: {
    creatingAppointment: "Creando cita...",
    appointmentCreated: "La cita ha sido creada!",
    overlappingAppointment:
      "La reserva se superpone con otra cita. ¿Quieres crear la cita de todos modos?",
    alreadyBooked:
      "La cita que estás intentando reservar ya ha sido reservada por otra persona.",
    overlappingWithNext:
      "La cita que estás intentando reservar se superpone con la siguiente cita. Por favor, elige un tiempo más corto.",
  },
  customerName: "Nombre del Cliente",
  createAppointmentMessage: {
    invalidTimeFormat: "Formato de tiempo inválido",
    creationCancelled: "Creación cancelada.",
    enterCustomerName: "Debes introducir un nombre de cliente.",
    selectService: "Debes seleccionar un servicio.",
    editEndTimeWithoutStart:
      "Intentado editar el tiempo de finalización sin establecer el tiempo de inicio.",
    createNewCustomer: "Crear nuevo cliente para {name}",
    customEvent: "Evento personalizado",
    createAppointment: "Crear cita",
    appointmentCreationFailed:
      "No se pudo crear la cita. Por favor, contacta con el servicio al cliente.",
  },
  bookingPageCustomerSettings: {
    auditLogs: {
      changedAssignmentType:
        "Changed setting 'Automatic  assignment new customer' to {setting}",
      changedCustomerAccountCreation:
        "Changed setting 'Create/Assign customer account automatically upon booking' to {setting}",
    },
  },
  bookingPageGeneralSettings: {
    auditLogs: {
      changedBookingLink: "Cambiado configuración 'Booking-ID' a {link}",
      changedCommonLink: "Cambiado configuración 'Common-ID' a {link}",
      changedLocationName:
        "Cambiado configuración 'Nombre de la ubicación' a {name}",
    },
    validation: {
      bookingLinkTooShort:
        "El enlace de reserva debe contener al menos 3 caracteres",
    },
  },
  mobileHeader: {
    home: "Inicio",
    calendar: "Calendario",
    customers: "Clientes",
    new: "Nuevo",
    orders: "Reservas",
    settings: "Configuración",
    log: "Log",
    statistics: "Estadísticas",
    overview: "Resumen",
    booking: "Reserva",
    staff: "Personal",
  },
  password: "Contraseña",
  dialogMessages: {
    confirm: "Confirmar",
    cancel: "Cancelar",
    no: "No",
  },
  appointmentList: {
    dialog: {
      delete_appointments: {
        message: "{0} citas serán eliminadas",
        header: "¿Eliminar citas?",
      },
      confirmationMessage: "{0} citas han sido eliminadas",
    },
    appointment: {
      deleteSelectedAppointments: "Eliminar citas seleccionadas",
      filter: "Filtrar",
      showMoreCustomerInfo: "Mostrar más información del cliente",
      noAppointments: "No hay citas",
      loading: "Cargando",
      status: {
        ny: "Nueva",
        ferdig: "Finalizada",
      },
      select: "Seleccionar",
    },
  },
  $vuetify: {
    badge: "Placa",
    close: "Cerrar",
    dataIterator: {
      noResultsText: "Ningún elemento coincide con la búsqueda",
      loadingText: "Cargando...",
    },
    dataTable: {
      itemsPerPageText: "Filas por página:",
      ariaLabel: {
        sortDescending: "Orden descendente.",
        sortAscending: "Orden ascendente.",
        sortNone: "Sin ordenar.",
        activateNone: "Pulse para quitar orden.",
        activateDescending: "Pulse para ordenar de forma descendente.",
        activateAscending: "Pulse para ordenar de forma ascendente.",
      },
      sortBy: "Ordenado por",
    },
    dataFooter: {
      itemsPerPageText: "Elementos por página:",
      itemsPerPageAll: "Todos",
      nextPage: "Página siguiente",
      prevPage: "Página anterior",
      firstPage: "Primera página",
      lastPage: "Última página",
      pageText: "{0}-{1} de {2}",
    },
    datePicker: {
      itemsSelected: "{0} seleccionados",
      nextMonthAriaLabel: "Próximo mes",
      nextYearAriaLabel: "Próximo año",
      prevMonthAriaLabel: "Mes anterior",
      prevYearAriaLabel: "Año anterior",
    },
    noDataText: "No hay datos disponibles",
    carousel: {
      prev: "Visual anterior",
      next: "Visual siguiente",
      ariaLabel: {
        delimiter: "Visual {0} de {1}",
      },
    },
    calendar: {
      moreEvents: "{0} más",
    },
    fileInput: {
      counter: "{0} archivos",
      counterSize: "{0} archivos ({1} en total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Navegación de paginación",
        next: "Página siguiente",
        previous: "Página anterior",
        page: "Ir a la página {0}",
        currentPage: "Página actual, página {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Puntuación {0} de {1}",
      },
    },
    statistics: {
      serviceName: "Servicio",
      bookings: "Reservas",
    },
  },
  serviceCategories: {
    nameRequired: "El nombre es obligatorio",
    confirmDelete: "¿Estás seguro de que deseas eliminar esta categoría?",
    deleteHeader: "Eliminar servicio",
    editCategory: "Editar categoría",
    name: "Nombre",
    cancel: "Cancelar",
    save: "Guardar",
    categoryName: "Nombre de la categoría",
    create: "Crear",
    sort: "Ordenar",
    table: {
      name: "Nombre",
      sort: "Ordenar",
    },
    validation: {
      nameRequired: "El nombre de la categoría es requerido",
    },
    createCategory: "Crear categoría",
  },
  bookingPageInformationSettings: {
    auditLogs: {
      changedInformationBoxText: "Cambiado el texto de la información.",
      changedShowInformationBox:
        "Cambiado la configuración 'Mostrar información' a {setting}",
    },
  },
  serviceOptions: {
    auditLogs: {
      changedShowServicePrice:
        "Cambiado la configuración 'Mostrar precio de servicio' a {setting}",
      changedMultipleServices:
        "Cambiado la configuración 'Selección múltiple de servicios' a {setting}",
    },
  },
  bookingPageFieldSettings: {
    auditLogs: {
      changedMultipleServices:
        "Cambiado la configuración 'Campo de cliente' a {field}",
    },
  },
  routes: {
    login: "Iniciar sesión",
    login_: "Iniciar sesión", // Note: "login " has a trailing space; adjusted key to "login_"
    Settings: "Configuración",
    CompanyInfo: "Información de la empresa",
    SystemSettings: "Configuración del sistema",
    Services: "Servicios",
    Categories: "Categorías",
    Resources: "Recursos",
    Subscription: "Suscripción",
    Accounts: "Cuentas",
    BookingAlerts: "Alertas de reservas",
    CustomerInformation: "Información del cliente",
    Booking: "Reserva",
    BookingSide: "Página de reserva",
    Options: "Opciones",
    Staff: "Personal",
    CalendarSettings: "Configuración del calendario",
    Newsletter: "Boletín",
    AuditLog: "Registro de auditoría",
    ExtraServices: "Servicios extra",
    BookingInformation: "Información de la reserva",
    CustomerAccount: "Cuenta de cliente",
    Kunder: "Clientes",
    Kunde: "Kunde",
    Kalender: "Calendario",
    KalenderBestilling: "Reserva en calendario",
    Hjem: "Inicio",
    oAuthCallBack: "OAuth de vuelta",
    Onboarding: "Onboarding",
    muscleGroupSelector: "Selector de grupo muscular",
    Statistikk: "Estadísticas",
    Eksporter: "Exportar",
    Bestillinger: "Reservas",
    Bestilling: "Reserva",
    Faktura: "Factura",
    Betalinger: "Pagos",
    Gavekort: "Tarjetas de regalo",
    Tjenester: "Servicios",
    Hendelser: "Eventos",
    Logg: "Log",
    AdminLogg: "Log de administrador",
    Debug: "Depuración",
    Register: "Registrar",
    StaffWorkingHours: "Horario Laboral",
    StaffGoogleCalendar: "Google Calendar",
    StaffFriday: "Días de Vacaciones",
    StaffServices: "Servicios",
    StaffInformation: "Información del Personal",
    StaffOverview: "Resumen del Personal",
  },
  fieldsettings: {
    hideDaysOutsideWorkingHours: "Ocultar días fuera de horario laboral",
    showServiceNameInEvent: "Mostrar nombre de servicio en evento",
    showTimeInEvent: "Mostrar tiempo en evento",
    showStaffInEvent: "Mostrar personal en evento",
  },
  statistics: {
    serviceName: "Servicio",
    bookings: "Reservas",
    customers: "Clientes",
  },
  all: "Todos",
  createService: {
    title: "Crear Servicio",
    assignToAllStaff: "Asignar a todos los empleados",
    assignToAllStaffDescription:
      "Si está marcado, el servicio se asignará a todos los empleados.",
    discount: "Descuento",
    discountDescription: "Si está marcado, el servicio tendrá un descuento.",
    newPrice: "Nuevo Precio",
    newPriceDescription: "El nuevo precio del servicio.",
    hideInBooking: "Ocultar en reserva",
    hideInBookingDescription: "Ocultar el servicio en la página de reserva.",
    serviceName: "Nombre del Servicio",
    serviceNameDescription: "El nombre del servicio.",
    description: "Descripción",
    descriptionDescription: "La descripción del servicio.",
    price: "Precio",
    priceDescription: "El precio del servicio.",
    duration: "Duración",
    durationDescription: "La duración del servicio en minutos.",
    durationForStaff: "Duración para Personal",
    durationForStaffDescription:
      "La duración en minutos del servicio para que el personal lo complete.",
    durationForCustomer: "Duración para Cliente",
    durationForCustomerDescription:
      "La duración en minutos del servicio como se muestra a los clientes.",
    color: "Color",
    colorDescription: "El color del servicio en el calendario.",
    showCustomColor: "Mostrar color personalizado",
    showCustomColorDescription:
      "Si está marcado, el servicio tendrá un color personalizado.",
    customColor: "Color personalizado",
    customColorDescription: "El color personalizado del servicio.",
    discountPrice: "Precio con descuento",
    discountPriceDescription: "El precio con descuento del servicio.",
    discountPriceChange: "Precio con descuento cambiado",
    discountPriceChangeDescription:
      "El precio con descuento del servicio ha sido cambiado.",
    validation: {
      priceRequired: "Precio es requerido",
      durationRequired: "Duración es requerida",
      serviceNameRequired: "Nombre del servicio es requerido",
      descriptionRequired: "Descripción es requerida",
      durationMustBeNumber: "Duración debe ser un número",
      priceMustBeNumber: "Precio debe ser un número",
      durationPositive: "Duración debe ser positiva",
      durationExceedsLimit: "Duración excede el límite",
      invalidDiscountPrice: "Precio con descuento inválido",
      discountPriceMustBeNumber: "Precio con descuento debe ser un número",
      priceNotNegative: "Precio debe ser positivo",
      discountPriceNotNegative: "Precio con descuento debe ser positivo",
    },
  },
  validation: {
    nameRequired: "Nombre es requerido",
  },
  writeHere: "Escribe aquí...",
  registerFirebase: {
    email: "Email",
    password: "Contraseña",
    passwordAgain: "Contraseña de nuevo",
    register: "Registrar",
    emailAlreadyInUse: "Email ya en uso",
    invalidEmail: "Email inválido",
    passwordTooWeak: "Contraseña demasiado débil",
    emailAndPasswordRequired: "Email y contraseña son requeridos",
    passwordsMustMatch: "Las contraseñas deben coincidir",
  },
  placeHolder: {
    typeToSearch: "Escribe para buscar",
  },
  selectDate: "Seleccionar fecha",
};
