import { render, staticRenderFns } from "./CreateAppointment.vue?vue&type=template&id=65704ac9&scoped=true&"
import script from "./CreateAppointment.vue?vue&type=script&lang=ts&"
export * from "./CreateAppointment.vue?vue&type=script&lang=ts&"
import style0 from "./CreateAppointment.vue?vue&type=style&index=0&id=65704ac9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65704ac9",
  null
  
)

export default component.exports