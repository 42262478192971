
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SettingsListItem from "./SettingsListItem.vue";
import { IStaffMemberDTO } from "@/types/AutoGenerated/reinforcedTypings";

@Component({
  name: "StaffSettings",
  components: {
    SettingsListItem,
  },
})
export default class StaffSettings extends Vue {
  @Prop({ type: Array, required: true })
  readonly staffMembers!: IStaffMemberDTO[];

  staffMembersForList = [];

  staffOverview = {
    id: 0,
    label: "",
    icon: "",
    route: "",
    disabled: false,
  };

  staffSubSettings = [];

  // Map routes to component names and titles
  routeComponentMap = {};

  @Watch("staffMembers")
  staffMembersChanged(newVal: IStaffMemberDTO[]) {
    this.staffMembersForList = newVal.map((staff) => ({
      ...staff,
      expanded: false,
    }));
  }

  // Dynamic route mapping for staff members
  getStaffRouteComponent(route: string) {
    // Extract the base route pattern by replacing the staff ID with a placeholder
    const baseRoute = route.replace(/\/\d+\//, "/{id}/");
    const componentMaps = {
      "/settings/staff/{id}/information": {
        component: "StaffMemberDetailsSettings",
        title: this.$t("settings.information").toString(),
      },
      "/settings/staff/{id}/services": {
        component: "StaffMemberServicesSettings",
        title: this.$t("settings.services").toString(),
      },
      "/settings/staff/{id}/working-hours": {
        component: "WorkingHours",
        title: this.$t("settings.workingHours").toString(),
      },
      "/settings/staff/{id}/friday": {
        component: "VacationDays",
        title: this.$t("settings.friday").toString(),
      },
      "/settings/staff/{id}/google-calendar": {
        component: "StaffMemberGoogleCalendarSettings",
        title: this.$t("settings.googleCalendar").toString(),
      },
    };
    return componentMaps[baseRoute];
  }

  created() {
    this.routeComponentMap = {
      "/settings/staff": {
        component: "StaffMembersOverview",
        title: this.$t("settings.overview").toString(),
      },
    };
    this.staffOverview = {
      id: 13,
      label: this.$t("settings.overview").toString(),
      icon: "mdi-account-supervisor-circle",
      route: "/settings/staff",
      disabled: false,
    };

    this.staffMembersForList = this.staffMembers.map((staff) => ({
      ...staff,
      expanded: false,
    }));

    this.staffSubSettings = [
      {
        id: 14,
        label: this.$t("settings.information"),
        icon: "mdi-account-circle",
        route: (staffId: number) => `/settings/staff/${staffId}/information`,
        disabled: false,
      },
      {
        id: 16,
        label: this.$t("settings.services"),
        icon: "mdi-star-circle",
        route: (staffId: number) => `/settings/staff/${staffId}/services`,
        disabled: false,
      },
      {
        id: 17,
        label: this.$t("settings.workingHours"),
        icon: "mdi-clock-outline",
        route: (staffId: number) => `/settings/staff/${staffId}/working-hours`,
        disabled: false,
      },
      {
        id: 18,
        label: this.$t("settings.friday"),
        icon: "mdi-calendar-clock",
        route: (staffId: number) => `/settings/staff/${staffId}/friday`,
        disabled: false,
      },
      {
        id: 19,
        label: this.$t("settings.googleCalendar"),
        icon: "mdi-calendar-sync",
        route: (staffId: number) =>
          `/settings/staff/${staffId}/google-calendar`,
        disabled: false,
      },
    ];
  }

  showSettingsDialog(route: string, disabled: boolean, staffMember?: any) {
    if (!disabled && route) {
      let componentInfo;

      // Check if it's a staff member specific route
      if (route.includes("/staff/") && route.split("/").length > 3) {
        componentInfo = this.getStaffRouteComponent(route);
        if (componentInfo && staffMember) {
          // Extract staffId from the route
          const staffId = route.split("/")[3];
          // Create a personalized title using staff member's name
          const personalizedTitle = `${componentInfo.title}`;
          // Emit event to parent to show dialog with staffId and staff member data in props
          this.$emit("show-settings-dialog", {
            component: componentInfo.component,
            title: personalizedTitle,
            props: {
              staffId,
              staffMember, // Pass the entire staff member object
            },
          });
          return;
        }
      }

      // Handle non-staff-specific routes
      componentInfo = this.routeComponentMap[route];
      if (componentInfo) {
        this.$emit("show-settings-dialog", {
          component: componentInfo.component,
          title: componentInfo.title,
          props: {},
        });
      }
    }
  }
}
