
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Mutation } from "vuex-class";
import { Watch } from "vue-property-decorator";
import CreateAppointment from "./global/CreateAppointment.vue";
import NavigationItems from "./navigation/NavigationItems.vue";
import SettingsMobile from "../pages/admin/SettingsMobile.vue";
import AuditLogger, { AuditLoggerType } from "../mixins/AuditLogger";

@Component({
  name: "MobileHeader",
  components: {
    CreateAppointment,
    NavigationItems,
    SettingsMobile,
  },
  mixins: [AuditLogger],
})
export default class MobileHeader extends Vue implements AuditLoggerType {
  @Mutation public setDefaultCustomerToBook!: Function;
  @Action public selectDateToBook!: Function;
  @Action getBookedAppointmentsForDay!: Function;

  public group2 = null;
  public bookOpen = false;
  public settingsOpen = false;

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  async getEvents(): Promise<void> {
    const date = new Date();
    await this.getBookedAppointmentsForDay(date);
  }

  @Watch("$route")
  async onRouterChange() {
    const p = this.$router.currentRoute.path;

    const routeMap = {
      "/": 0,
      "/admin": 0,
      "/kunder": 2,
      "/kalender": 1,
      "/hendelser": 4,
      "/settings": 5,
      "/bestillinger": 3,
    };

    this.group2 = routeMap[p] || 21328203;
  }

  async addedAppointment() {
    this.bookOpen = false;
    await this.getEvents();
  }

  book() {
    this.logAudit(" opened new appointment @ mobile menu");
    this.setDefaultCustomerToBook(null);

    const now = new Date();
    this.selectDateToBook({
      year: now.getFullYear(),
      month: now.getMonth(),
      day: now.getDate(),
    });
    this.bookOpen = true;
  }

  openSettings() {
    this.logAudit(" opened settings dialog");
    this.settingsOpen = true;
  }

  clickItem(item) {
    this.group2 = item.index;

    if (item.action) {
      item.action();
    } else {
      this.logAudit(" click mobile item: " + item.route);
      this.$router.push(item.route).catch(() => {});
    }
  }

  public toggleDropDownMenu(): void {
    this.$store.commit("setMobileNavigation", true);
    this.logAudit(" opened mobile menu");
  }

  get hideMobileFooter() {
    return this.$store.getters.hideMobileFooter;
  }

  created() {
    this.onRouterChange();
  }
}
