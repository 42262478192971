var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"mb-0 px-4",attrs:{"flat":""}},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"align":"center"}},[(_vm.$vuetify.breakpoint.mdAndUp || _vm.ignoreMobileLayout)?[(_vm.showLabelSection)?_c('v-col',{class:{
          'flex-grow-1': !_vm.isSwitch,
          'col-9': _vm.isSwitch,
        },staticStyle:{"padding-left":"0"}},[_c('h4',[_vm._v(_vm._s(_vm.label))]),(_vm.description)?_c('p',{staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),_vm._t("description")],2):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end",class:{
          'col-3': _vm.isSwitch && _vm.showLabelSection,
          'col-12': !_vm.showLabelSection,
        },staticStyle:{"flex-shrink":"0"},attrs:{"xs":"12"}},[_vm._t("default")],2)]:_c('div',{staticClass:"w-100"},[(_vm.showLabelSection)?_c('v-col',{staticClass:"col-12 pt-4 pb-0"},[_c('h4',[_vm._v(_vm._s(_vm.label))]),(_vm.description)?_c('p',{staticStyle:{"white-space":"normal","margin-bottom":"0"}},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),_vm._t("description")],2):_vm._e(),_c('v-col',{class:{
          'col-12': true,
          'pb-2': _vm.showLabelSection,
          'py-0': !_vm.showLabelSection,
        }},[_vm._t("default")],2)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }