
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { actionStringCheckout, ISubscriptionForm } from "@/store/checkout";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import Loader from "@/components/global/Loader.vue";
import StripePayment from "./StripePayment.vue";
import { DialogActions, DialogType, IDialog } from "@/store/dialog";
import {
  ISubscriptionPlan,
  ISubscriptionStatus,
  Subscription,
} from "@/types/HasSubscription";
import { actionStringUser, getterStringUser, IUser } from "@/store/user";
import {
  formatDate,
  FormatSpaceType,
  formatTime,
} from "@/types/formatDateHelper";
import {
  IAppointmentDTO,
  IAppointmentForWeeklyCalender,
  IAuditLogDTO,
  IStaffMemberDTO,
  IUserDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { IAppointmentPatch } from "@/store/appointments/appointments";
import { formatCurrency } from "@/Utilities/currencyHelper";
@Component({
  name: "appointmentsummary",
  components: { StripePayment, Loader, CustomButton },
})
export default class AppointmentSummary extends Vue {
  @Prop({ default: null }) selectedEvent: IAppointmentForWeeklyCalender;

  @Getter public staffMembers: Partial<IStaffMemberDTO>[];
  @Getter user: IUserDTO;
  @Action public patchAppointment: Function;
  @Action public changeAppointmentStaff: Function;

  async viewCustomer(customerId) {
    await this.$router.push("/kunder/" + customerId + "/").catch(() => {});
  }

  formatEventDateString(event: IAppointmentForWeeklyCalender) {
    if (event.start == null) {
      return "";
    }

    let toDateObject = new Date(event.start as string);
    return formatDate(toDateObject, FormatSpaceType.DOT);
  }

  formatCurrency(value: number): string {
    return formatCurrency(value);
  }

  formatEventTimeString(event: IAppointmentForWeeklyCalender) {
    let start = event.start;
    let end = event.end;

    if (start == null) {
      return "";
    }

    let toDateObject = new Date(start as string);
    let toDateObject2 = new Date(end as string);
    return (
      formatTime(toDateObject.getHours(), toDateObject.getMinutes()) +
      " - " +
      formatTime(toDateObject2.getHours(), toDateObject2.getMinutes())
    );
  }

  get hasMultipleStaff() {
    return this.staffMembers.length > 1;
  }

  async onChangeStaffMember(e) {
    console.log("On change staff member: ", e);

    const payload = {
      appointmentId: this.selectedEvent.appointmentId,
      staffMemberId: e,
    };

    let audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: "",
      message:
        " quickly changed staff member to " +
        e +
        " in appointment " +
        this.selectedEvent.appointmentId,
      hideForUser: true,
    };
    await this.changeAppointmentStaff(payload);

    this.$emit("close");

    await this.$store.dispatch("postAuditLog", audit);
  }

  updateAdminComment(event, selectedEvent: IAppointmentForWeeklyCalender) {
    let update: Partial<IAppointmentDTO> = {
      adminNote: selectedEvent.adminNote,
    };

    const payload: IAppointmentPatch = {
      appointmentId: selectedEvent.appointmentId,
      patch: update,
    };

    this.patchAppointment(payload);
  }

  get price() {
    if (this.selectedEvent.appointmentPrice != null)
      return this.selectedEvent.appointmentPrice;
    //@ts-ignore
    return this.selectedEvent.appointmentTypePrice;
  }

  formatDateCorrectly(day: string, month: string, year: string): string {
    let date = month + "/" + day + "/" + year;
    let datObject = new Date(date);
    return formatDate(datObject, FormatSpaceType.DOT);
  }

  formatTimeCorrectly(hour: string | number, minute: string | number): string {
    return formatTime(hour, minute);
  }

  get staffMembersProvidingService() {
    return this.staffMembers.filter((x) => {
      return this.selectedEvent.staffMembersProvidingService.includes(x.id);
    });
  }

  formatAppointmentEnd(event) {
    let start = new Date(
      event.year,
      event.month - 1,
      event.day,
      event.hour,
      event.minute,
      0
    );
    //@ts-ignore
    let end = new Date(start.getTime() + event.appointmentDuration * 1000 * 60);
    let h = end.getHours();
    let m = end.getMinutes();
    return this.formatTimeCorrectly(h, m);
  }
}
