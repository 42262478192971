import Vue from "vue";

import VueI18n from "vue-i18n";
import en from "./locales/en";
import es from "./locales/es";
import no from "./locales/no";

import vuetify_es from "vuetify/lib/locale/es";
import vuetify_en from "vuetify/lib/locale/en";
import vuetify_no from "vuetify/lib/locale/no";

Vue.use(VueI18n);

// Load locale on app startup
const savedLocale = localStorage.getItem("locale");

let defaultLocale = "no";

if (savedLocale) {
  defaultLocale = savedLocale;
}

const i18n = new VueI18n({
  locale: defaultLocale, // Default language
  messages: { en, es, no },
});

export { i18n }; // Export the i18n instance for use in raw typescript files

import { store } from "./store/store";
import router from "./router";
import App from "./App.vue";
import "./Utilities/authHandler";
import VueStripeCheckout from "vue-stripe-checkout";
import Inject from "./plugins/Inject";
import { AppointmentDistribution } from "@/types/AppointmentDistribution";
import VueQrcodeReader from "vue-qrcode-reader";
import { Sector } from "@/types/Sector";
import { ButtonSize, ModalContent } from "@/types/ButtonSize";
import { MessageType } from "@/types/messageBarTypes";
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import VueGtag from "vue-gtag";
// import VueGapi from 'vue-gapi''
import vuetify from "@/plugins/vuetify"; // path to vuetify export

import * as Sentry from "@sentry/vue";

// import { registerGlobalComponents } from "@/plugins/global-components";

vuetify.framework.lang.current = vuetify_no;

//PWA support???
import wb from "./service-worker";
Vue.prototype.$workbox = wb;

// registerGlobalComponents(); //id like this

Sentry.init({
  Vue,
  dsn: "https://15078f0adca84c6dbc28eabc305e39f3@o306737.ingest.us.sentry.io/1765691",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  trackComponents: true,
});

import "./plugins/axiosSentry";

new Vue({
  i18n,
  router,
  store,
  vuetify: vuetify,
  mounted() {
    // Access query parameters after the app has mounted
    const queryParams = this.$route.query;
    const lang = queryParams.lang; // Read the 'lang' query parameter
    if (lang) {
      i18n.locale = lang;
    } else {
      const domain = window.location.hostname;
      if (domain.includes("citaro")) {
        i18n.locale = "es"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
      }
    }
  },
  render: (h) => h(App),
}).$mount("#app");

export let APIURL2: string;
export let USE_DEV_SITE: boolean;
export let IS_DEVELOPING: boolean;
export let BOOKING_URL: string;
export let NEW_BOOKING_URL: string;
export let CITARO_BOOKING_URL: string;

// IS_DEVELOPING = window.location.hostname === "localhost";
USE_DEV_SITE = false;

let STRIPE_PUBLIC_KEY = "pk_test_pOnz4aVIcgOq5uWsMd1KrO5z";
//APIURL2 = "https://localhost:5001/api/";
APIURL2 = "https://timebestillerwebapp.azurewebsites.net/api/";

//Consider using the colombian api for colombian customers. the performance though isnt big enough to be worth it.
//APIURL2 = "https://citaro-api-fegagcgng7f0gefs.mexicocentral-01.azurewebsites.net/api/";

// APIURL2 = "https://localhost:5001/api/";

IS_DEVELOPING = true;

try {
  // @ts-ignore
  BOOKING_URL = azurevar_bookingurl_endazurevar;
} catch (e) {
  BOOKING_URL = "http://localhost:8081/";
  BOOKING_URL = "http://booking.availify.no/"; //RIght now im deploying manually from firbase, so i have to do this.
  CITARO_BOOKING_URL = "http://booking.citaro.app/";
}

try {
  // @ts-ignore
  NEW_BOOKING_URL = azurevar_newbookingurl_endazurevar;
} catch (e) {
  NEW_BOOKING_URL = "http://localhost:8081/";
  NEW_BOOKING_URL = "http://booking.availify.no/"; //again, temporary
  CITARO_BOOKING_URL = "http://booking.citaro.app/";
}

try {
  // @ts-ignore
  APIURL2 = azurevar_api_url_token_endazurevar;
  IS_DEVELOPING = false;
} catch (error) {
  if (window.location.hostname !== "localhost") {
    console.warn("API URL IS UNDEFINED");
  }
}
try {
  // @ts-ignore
  STRIPE_PUBLIC_KEY = azurevar_stripe_pk_key_token_endazurevar;
} catch (e) {
  if (window.location.hostname !== "localhost") {
    console.warn("STRIPE TOKEN IS UNDEFINED");
  }
}

Vue.use(VueStripeCheckout, STRIPE_PUBLIC_KEY);

// Translation provided by Vuetify (typescript)
//import no from 'vuetify/src/locale/no'

// Vue.use(Vue2TouchEvents);

//TODO: Consider eventually?
// async function setLocaleBasedOnIP() {
//   try {
//     const response = await axios.get('https://ipinfo.io?token=YOUR_API_TOKEN');
//     if (response.data && response.data.country === 'CO') {
//       i18n.global.locale = 'es'; // set locale to Spanish for Colombia
//     }
//   } catch (error) {
//     console.error('Error fetching IP info:', error);
//     // Use default locale
//   }
// }

Vue.use(VueGtag, {
  config: { id: "G-1FVGFKQSCW" },
});
// Vue.use(VueGapi, {
//   apiKey: 'AIzaSyBnqnHsXguX7mk97vUsnXT8X0EmSZ6_6uQ',
//   clientId: '605449339616-o0rj3sod6m1v9i0f820jho90jk7be8u8.apps.googleusercontent.com',
//   discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//   scope: 'https://www.googleapis.com/auth/calendar',
// })

Vue.use(wysiwyg, { locale: "no", hideModules: { image: true } });
Vue.use(VueQrcodeReader);
Vue.use(Inject, {
  AppointmentDistribution,
  Sector,
  ButtonSize,
  ModalContent,
  MessageType,
});
// Vue.use(Trend)
Vue.config.productionTip = false;

export function getStripePublicKey() {
  return STRIPE_PUBLIC_KEY;
}

export function getApiUrl() {
  return APIURL2;
}
