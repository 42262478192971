export default {
  welcome: "Velkommen",
  months: {
    january: "Januar",
    february: "Februar",
    march: "Mars",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Desember",
  },
  days: {
    sunday: "Søn",
    monday: "Man",
    tuesday: "Tir",
    wednesday: "Ons",
    thursday: "Tor",
    friday: "Fre",
    saturday: "Lør",
  },
  agendaTitle: "Agenda",
  update: {
    title: "Oppdater",
  },
  subscriptionWarnings: {
    message1:
      "Hei! Du har nå prøvd programmet gratis i 14 dager. Vi håper du finner det nyttig.",
    message2:
      "Hvis du ønsker å fortsette å bruke programmet, vennligst kjøp et abonnement under 'Innstillinger' -> 'Abonnement'",
    message3: "Hvis du har noen spørsmål, vennligst kontakt oss. Takk!",
  },
  buttons: {
    gotoSubscription: "Gå til Abonnement",
    close: "Lukk",
    ok: "Ok",
    save: "Lagre",
    delete: "Slett",
    cancel: "Avbryt",
    edit: "Rediger",
    create: "Opprett",
  },

  noAppointmentsToday: "Ingen avtaler i dag!",
  readNotes: "Les notater",
  readCustomerComment: "Les kundens kommentar",
  dialog: {
    customerComment: "Kundens Kommentar",
    notes: "Notater",
    confirmDeleteService: "Er du sikker på at du vil slette denne tjenesten?",
    deleteServiceHeader: "Slett Tjeneste",
  },
  messages: {
    updated: "Oppdatert.",
    categoryChanged: "Kategorien har blitt endret.",
    categoryOrderChanged: "Kategorikategorien har blitt endret.",
    serviceOrderChanged: "Tjenestekategorien har blitt endret.",
    categoryUpdated: "Kategorien har blitt oppdatert.",
    newCategoryAdded: "Ny kategori har blitt lagt til.",
    categoryDeleted: "Kategorien har blitt slettet.",
    newServiceAdded: "Ny tjeneste har blitt lagt til.",
    couldNotAddService: "Kunne ikke legge til ny tjeneste.",
    serviceDeleted: "Tjenesten har blitt slettet.",
    serviceUpdated: "Tjenesten har blitt oppdatert.",
  },
  staff: "Ansatte",
  date: "Dato",
  service: "Tjeneste",
  price: "Pris",
  customerAccount: "Kundekonto",
  automaticAssignExistingCustomerAccount:
    "Tildel eksisterende kundekonto automatisk til nye bestillinger basert på felt.",
  createAssignCustomerAccountAutomatically:
    "Opprett/Tildel kundekonto automatisk ved bestilling",
  customerAccountWillBeAssignedBasedOnPhoneNumber:
    "Kundekonto tildeles eksisterende basert på telefonnummer.",
  newCustomField: "Nytt Egendefinert Felt",
  selectFieldsCustomerFillsWhenBooking:
    "Velg feltene kunden fyller ut ved bestilling",
  optional: "Valgfritt",
  customFields: "Egendefinerte Felt",
  add: "Legg til",
  link: "Lenke",
  yourBookingLinkWillBe: "Din bestillingslenke vil være {link}",
  connectSystemWithCommonBookingLink:
    "Koble systemet med en felles bestillingslenke",
  yourCommonBookingLinkWillBe: "Din felles bestillingslenke vil være {link}",
  bookingID: "Bestillings-ID",
  commonID: "Felles ID",
  locationName: "Stedsnavn",
  showInformationBox: "Vis Informasjonsboks",
  shouldInformationBoxBeDisplayed:
    "Skal informasjonsboksen vises på bestillingssiden?",
  informationBox: "Informasjonsboks",
  informationForCustomerOnBookingPage:
    "Informasjon til kunden på forsiden av bestillingssiden",
  cancellationInformation: "Avbestillingsinformasjon",
  customizeInformationCustomerSeesWhenCancelling:
    "Tilpass informasjonen kunden ser ved avbestilling",
  receiveEmailOnAppointment: "Motta e-post ved avtale",
  doYouWantToReceiveEmailOnNewAppointment:
    "Ønsker du å motta en e-post når en ny avtale er mottatt?",
  whichEmailAddressToReceive:
    "Hvilken e-postadresse skal motta e-posten ved bestilling?",
  receiveSMSOnAppointment: "Motta SMS ved avtale",
  doYouWantToReceiveSMSOnNewAppointment:
    "Ønsker du å motta en SMS når en ny avtale er mottatt?",
  whichPhoneNumberToReceiveSMS:
    "Hvilket telefonnummer skal motta SMS ved bestilling?",
  ssn: "Personnummer",
  birthdate: "Fødselsdato",
  gender: "Kjønn",
  city: "By",
  postalCode: "Postnummer",
  pause: "Pause",
  event: "Hendelse",
  from: "Fra",
  to: "Til",
  durationMismatch:
    "Varigheten av bestillingen avviker fra tjenestens varighet.",
  priceMismatch: "Prisen på bestillingen avviker fra tjenestens pris.",
  cancel: "Avbryt",
  create: "Opprett",
  confirm: "Bekreft",
  invalidTimeFormat: "Ugyldig tidsformat",
  enterCustomerName: "Du må angi et kundenavn.",
  selectService: "Du må velge en tjeneste.",
  editEndTimeWithoutStart: "Forsøkte å redigere sluttid uten å sette starttid.",
  minutesAbbreviation: "min",
  currency: "NOK",
  creationCancelled: "Oppretting avbrutt.",
  customEvent: "Egendefinert hendelse",
  monthlyEarningsTitle: "Inntekter for {month}",
  totalCustomers: "Totalt Antall Kunder",
  earningsTrend: "Inntektstrend",
  servicesBooked: "Bestilte Tjenester",
  moneyEarnedIn: "Inntekter i {month}",
  appointmentsIn: "Avtaler i {month}",
  appointmentBookingLinkTitle: "Bestillingslenke for Avtale",
  appointmentBookingLinkDescription:
    "Bruk lenken nedenfor for å bestille en avtale.",
  bookingLinkLabel: "Bestillingslenke",
  copyLinkButton: "Kopier Lenke",
  linkCopiedMessage: "Lenke kopiert til utklippstavlen!",
  closePreview: "Lukk Forhåndsvisning",
  previewBookingPage: "Forhåndsvis Bestillingsside",
  contactUs: "Kontakt Oss",
  sidebar: {
    home: "Hjem",
    customers: "Kunder",
    calendar: "Kalender",
    settings: "Innstillinger",
    appointments: "Avtaler",
    overview: "Oversikt",
    payments: "Betalinger",
    invoices: "Fakturaer",
    statistics: "Statistikk",
    export: "Eksport",
    contactSupport: "Kontakt Support",
    log: "Logg",
    notifications: "Varslinger",
    journal: "Journal",
  },
  name: "Navn",
  email: "E-post",
  phoneNumber: "Telefonnummer",
  address: "Adresse",
  postalCodeCity: "Postnummer/By",
  edit: "Rediger",
  created: "Opprettet",
  deleteSelectedCustomers: "Slett valgte kunder",
  search: "Søk",
  searchForCustomer: "Søk etter kunde",
  noCustomersYet: "Du har ingen kunder ennå",
  noSearchResults: "Ingen søkeresultater",
  customerWithoutName: "Kunde uten navn",
  confirmDeleteCustomers: "Er du sikker på at du vil slette {count} kunder?",
  deleteCustomerHeader: "Slett kunde(r)?",
  customersDeleted: "{count} kunder er slettet",
  customer: {
    notFound: "Kunde ikke funnet.",
    name: "Navn",
    email: "E-post",
    phoneNumber: "Telefonnummer",
    address: "Adresse",
    postalCodeCity: "Postnummer/By",
    edit: "Rediger",
    created: "Opprettet",
    deleteSelectedCustomers: "Slett valgte kunder",
    search: "Søk",
    searchForCustomer: "Søk etter kunde",
    noCustomersYet: "Du har ingen kunder ennå",
    noSearchResults: "Ingen søkeresultater",
    customerWithoutName: "Kunde uten navn",
    confirmDeleteCustomers: "Er du sikker på at du vil slette {count} kunder?",
    deleteCustomerHeader: "Slett kunde(r)?",
    customersDeleted: "{count} kunder er slettet",
  },
  calendar: {
    week: "Uke",
    month: "Måned",
    day: "Dag",
    sideBySide: "Side om side",
    threeFourDays: "3-4 dager",
    heading: "Kalender",
  },
  appointment: {
    deleteSelectedAppointments: "Slett valgte avtaler",
    filter: "Filter",
    all: "Alle",
    showMoreCustomerInfo: "Vis mer kundeinformasjon",
    noAppointments: "Ingen avtaler",
    select: "Velg",
    loading: "Laster... Vennligst vent...",
    status: {
      ny: "Ny",
      invoiceCreated: "Faktura Opprettet",
      invoiceSent: "Faktura Sendt",
      invoicePaid: "Faktura Betalt",
      ferdig: "Fullført",
    },
    comment: "Kommentar",
    adminNote: "Notat",
    created: "Opprettet",
    number: "Nummer",
    service: "Tjeneste",
    time: "Tid",
    customer: "Kunde",
    appointmentStatus: "Status",
    createAppointment: "Opprett Bestilling",
    editAppointment: "Rediger Bestilling",
  },
  companyInfo: {
    companyName: "Bedriftsnavn",
    companyNameDescription: "Bedriftsnavnet som vises til kunden",
    companyAddress: "Bedriftsadresse",
    companyAddressDescription: "Bedriftsadressen som vises til kunden",
    phoneNumber: "Telefonnummer",
    phoneNumberDescription:
      "Telefonnummeret til bedriften som vises til kunden",
    email: "E-post",
    emailDescription: "E-posten til bedriften som vises til kunden",
    companyLogo: "Bedriftslogo",
    companyLogoDescription: "Logoen til bedriften som vises til kunden",
  },
  snackbar: {
    imageTooLarge: "Bildet må være mindre enn 200 KB",
    invalidImageFormat: "Bildet må være i JPG- eller PNG-format",
    enterServiceName: "Tjenestenavn må oppgis.",
    negativeDiscountPrice: "Rabattpris kan ikke være negativ.",
    enterDuration: "Varighet i minutter må oppgis.",
    durationMustBeNumber: "Varighet i minutter må være et tall.",
    priceMustBeNumber: "Pris må være et tall.",
    negativePrice: "Pris kan ikke være negativ.",
    invalidDuration: "Ugyldig varighet.",
    durationExceedsLimit:
      "En enkelt tjeneste kan ikke vare lenger enn 12 timer.",
  },
  account: {
    registerNewAccount: "Registrer Ny Konto",
    editAccount: "Rediger Konto",
    allAccounts: "Alle Kontoer",
    lastSignIn: "Sist logget inn: {date}",
    created: "Opprettet: {date}",
    yourAccount: "Din Konto",
  },
  role: {
    administrator: "Administrator",
    user: "Bruker",
  },
  logout: "Logg ut",
  changePassword: "Endre Passord",
  logoutConfirm: "Vil du logge ut?",
  forgotPassword: "Vil du sende en tilbakestillingslenke til {email}?",
  passwordResetEmailSent: "En e-post for tilbakestilling av passord er sendt.",
  subscription: {
    yourPlan: "Ditt Abonnement",
    nextPayment: "Neste Betaling",
    previousPayment: "Forrige Betaling",
    paymentInformation: "Betalingsinformasjon",
    cardEnding: "Visa som slutter på {card}",
    noTrial:
      "Din prøveperiode har utløpt. Kjøp et abonnement for å fortsette å bruke programmet.",
    paymentIssue: "Det er et problem med betalingen. Kontakt oss.",
    trialDaysLeft: "Du har {days} dager igjen av din gratis prøveperiode.",
    cancelledWithDate:
      "Abonnementet fornyes ikke neste måned. Det er aktivt til {date}.",
    cancelledWithoutDate:
      "Abonnementet fornyes ikke neste måned. Det er aktivt til slutten av måneden.",
    active: "Du har et aktivt abonnement.",
    yearlyWithSMS:
      "Du har et abonnement som er gyldig til {date} med SMS inkludert.",
    monthlyCost:
      "Abonnementet koster 250 kr per måned, uten bindingstid. Si opp når som helst. Ved oppsigelse varer abonnementet til neste betalingsdato.",
    automaticPayment:
      "Betalingen er automatisk per måned. Hvis du foretrekker faktura, vennligst be om det.",
    smsCost:
      "Hvis du har valgt å sende kvitteringer/påminnelser via SMS, eller SMS-varsling ved bestilling, koster det 1 kr per SMS og legges automatisk til betalingen.",
    purchase: "Kjøp abonnement",
    reactivate: "Reaktiver abonnement",
    cancel: "Avbryt abonnement",
    cancelSubscriptionConfirm:
      "Er du sikker på at du vil avbryte abonnementet?",
    subscription: "Abonnement",
  },
  createCustomer: "Opprett Kunde",
  editCustomer: "Rediger Kunde",
  lastName: "Etternavn",
  dateOfBirth: "Fødselsdato",
  male: "Mann",
  female: "Kvinne",
  selectInsurance: "Velg Forsikring",
  noInsuranceData: "Forsikringer kan legges til under innstillinger.",
  customerUpdated: "Kunde oppdatert.",
  customerCreated: "Kunde opprettet.",
  settings: {
    general: "Generelt",
    companyInfo: "Bedriftsinformasjon",
    subscription: "Abonnement",
    accounts: "Kontoer",
    services: "Tjenester",
    extraServices: "Ekstratjenester",
    categories: "Kategorier",
    resources: "Ressurser",
    options: "Valg",
    staff: "Ansatte",
    calendar: "Kalender",
    functions: "Funksjoner",
    newsletter: "Nyhetsbrev",
    customerAccounts: "Kundekontoer",
    invoicesAndPayments: "Fakturaer og Betalinger",
    booking: "Bestilling",
    information: "Informasjon",
    alerts: "Varsler",
    bookingSide: "Bestillingsside",
    customerInformation: "Kundeinformasjon",
    customerAccount: "Kundekontoer",
    workingHours: "Arbeidstider",
    friday: "Fridager",
    googleCalendar: "Google Kalender",
    overview: "Oversikt",
    customFields: "Egendefinerte Felt",
    giftCards: "Gavekort",
    customerFollowUp: "Oppfølging av Kunde",
    cashRegisterSystem: "Kassesystem",
    multipleLocations: "Flere Steder",
    system: "System",
    auditLog: "Handlingslogg",
    staffUpdated: "Ansatte oppdatert.",
    staffDeleted: "Ansatte slettet.",
  },
  resourceName: "Ressursnavn",
  description: "Beskrivelse",
  save: "Lagre",
  quantity: "Mengde",
  multipleServicesQuestion:
    "Skal kunden kunne velge flere tjenester i én bestilling?",
  fields: "Felt",
  showServicePrice: "Vis pris på tjenester",
  serviceName: "Tjenestenavn",
  sort: "Sorter",
  duration: "Varighet",
  category: "Kategori",
  extraServices: "Ekstratjenester",
  boolean: {
    yes: "Ja",
    no: "Nei",
    on: "På",
    off: "Av",
  },
  categories: "Kategorier",
  services: "Tjenester",
  staffMembersOverview: {
    minOneStaffRequired:
      "Du må ha minst én ansatt for at programmet skal fungere.",
    confirmDeleteStaff: "Vil du slette denne ansatte?",
    staff: "Ansatte",
    yourStaff: "Dine Ansatte",
    newStaff: "Ny Ansatt",
    firstName: "Fornavn",
    lastName: "Etternavn",
    emailAddress: "E-postadresse",
    phoneNumber: "Telefonnummer",
    created: "Opprettet",
    delete: "Slett",
    lastLoggedIn: "Sist Innlogget",
    editStaff: "Rediger Ansatt",
  },
  staffMemberDetails: {
    firstName: "Fornavn",
    lastName: "Etternavn",
    phoneNumber: "Telefonnummer",
    emailAddress: "E-postadresse",
    colorForCalendar: "Farge for Kalender",
    selectColor:
      "Velg 'Farge' under 'Kalender' -> 'Vis ansatte i hendelse' for å velge en farge for ansatte.",
    save: "Lagre",
  },
  staffMemberGoogleCalendar: {
    confirmRemoveSync: "Er du sikker på at du vil fjerne synkroniseringen?",
    syncGoogleCalendar: "Synkroniser Google Kalender med Availify",
    start: "Start",
    continue: "Fortsett",
    removeSync: "Fjern Synkronisering",
  },
  vacationDays: {
    markDayAs: "Merk dagen som:",
    available: "Tilgjengelig",
    notAvailable: "Ikke Tilgjengelig",
  },
  workingHours: {
    timeRequired: "Tid er påkrevd",
    invalidFormat: "Ugyldig format (TT:MM)",
    invalidTimeFormat:
      "Kunne ikke oppdatere åpningstider. Sjekk om du har angitt riktig format (TT:MM)",
    updated: "Åpningstider oppdatert.",
    availableOnThisDay: "Tilgjengelig denne dagen: {value}",
    openingHourFor: "Åpningstid for {day}",
    closingHourFor: "Stengetid for {day}",
    breaks: "Pauser",
    start: "Start",
    end: "Slutt",
    addBreak: "Legg til Pause",
    save: "Lagre",
  },
  generalBookingSettings: {
    customerVerification: "Kundeverifisering",
    customerVerificationDescription:
      "Hva må kunden bekrefte for å bestille en avtale?",
    cancellationModification: "Avbestilling/Endring av Avtaler",
    cancellationModificationDescription:
      "Skal kunden kunne endre/avbestille avtalen?",
    minimumHoursBefore: "Minste timer før avtalen",
    minimumHoursBeforeDescription:
      "Antall timer før avtalen som kunden kan avbestille",
    remindersReceipts: "Påminnelser/Kvitteringer til Kunder",
    remindersReceiptsDescription:
      "Hvordan skal kvitteringer/påminnelser for avtalen sendes?",
    alwaysSendReceipt: "Send alltid kvittering",
    alwaysSendReceiptDescription: "Boksen trenger ikke å være merket av kunden",
    alwaysSendReminder: "Send alltid påminnelse",
    alwaysSendReminderDescription:
      "Boksen trenger ikke å være merket av kunden",
    bookingRestrictions: "Bestillingsbegrensninger",
    bookingRestrictionsDescription:
      "Hvor mange dager i forveien kan en kunde bestille?",
    earlyBooking: "Hvor tidlig kan en kunde bestille før avtalen?",
    earlyBookingDescription: "Antall timer før avtalen kan bestilles",
    noVerification: "Ingen Verifisering",
    email: "E-post",
    sms: "SMS",
    auditVerificationChanged: "Endret innstilling 'Kundeverifisering'",
    auditCancellationChanged:
      "Endret innstilling 'Avbestilling/Endring av Avtaler'",
    auditHoursToCancelChanged:
      "Endret innstilling 'Minste timer før avbestilling av avtale' til {hours} timer",
    auditReceiptReminderChanged:
      "Endret innstilling 'Påminnelser/Kvitteringer til Kunder'",
    auditMaxDaysChanged:
      "Endret innstilling 'Bestillingsbegrensninger' antall dager en kunde kan bestille i forveien til {days} dager.",
    auditMinHoursChanged:
      "Endret innstilling 'Bestillingsbegrensninger' hvor tidlig en kunde kan bestille før avtalen {hours} timer.",
    emailRequired:
      "E-postfeltet er satt til påkrevd fordi informasjon sendes via e-post.",
    phoneRequired:
      "Telefonnummerfeltet er satt til påkrevd fordi kvitteringer og påminnelser sendes via SMS.",
    days: "Dager",
    hours: "Timer",
  },
  bookingDistribution: {
    timeDivision: "Tidsinndeling",
    selectTimeSlots: "Velg tidsluker som kan bestilles.",
    confirmChange:
      "Er du sikker på at du vil endre tidsinndelingen til {minutes} minutter?",
    changeTimeFormat: "Endre Tidsformat",
    auditChange: "Endret tidsinndeling til {minutes} minutter for bestilling",
  },
  hideDaysOutsideWorkingHours: "Skjul dager utenfor arbeidstid",
  cannotActivateWithMultipleStaff: "Kan ikke aktiveres med flere ansatte",
  events: "Hendelser",
  showServiceNameInEvent: "Vis tjenestens navn i hendelsen",
  showStaffInEvent: "Vis ansatte i hendelsen",
  showTimeInEvent: "Vis tid i hendelsen",
  color: "Farge",

  newsletter: "Nyhetsbrev",
  askCustomersToSignUpForNewsletter:
    "Be kunder melde seg på nyhetsbrevet ved bestilling",
  subscribers: "Abonnenter",
  noSubscribersYet: "Her vil det være en oversikt over alle abonnenter",
  signedUp: "Registrert",
  loading: "Laster... Vennligst vent...",
  downloadExcel: "Last ned Excel (.xlsx)",
  exportAppointments: "Eksporter alle avtaler",
  eventsOverview: {
    message: "Melding",
    time: "Tid",
    comment: "Kommentar",
    booking: "Bestilling",
    receipt: "Kvittering",
    reminder: "Påminnelse",
    seen: "Sett",
    new: "Ny",
    previous: "Forrige",
    noEvents: "Du har ingen hendelser",
    guest: "Gjest",
    critical: "Kritisk",
    info: "Info",
    warning: "Advarsel",
    customer: "Kunde",
    errorWithSmsSending:
      "Noe gikk galt med SMS-sending. SMS-verifisering er deaktivert.",
    timeAddedToExistingCustomer:
      "Bestilt tid ble automatisk lagt til eksisterende kunde - men det var forskjeller.",
    timeCancelled: "Bestilt tid ble kansellert.",
    timeChanged: "Bestilt tid ble endret.",
    newCustomer: "Ny kunde.",
    customerComment: "Kundekommentar",
    bookingCancelled: "Bestilling avbrutt",
    bookingChanged: "Bestilling endret",
  },
  onboarding: {
    user: "Bruker",
    company: "Bedrift",
    workingHours: "Arbeidstider",
    services: "Tjenester",
    finish: "Fullfør",
    pickWorkingHours: "Velg Arbeidstider",
    createServices: "Opprett Tjenester",
    yourUser: "Din Bruker",
    yourCompany: "Din Bedrift",
  },
  auditOverview: {
    event: "Hendelse",
    time: "Tid",
    comment: "Kommentar",
    noChanges: "Her vil det være en oversikt over alle endringene du gjør",
    customer: "Kunde",
    booking: "Bestilling",
    settings: "Innstillinger",
    staff: "Ansatte",
    track: "Spor",
    service: "Tjeneste",
    readComment: "Les kommentar i logg",
  },
  customerModal: {
    notes: "Notater",
    createdByCustomer: "Opprettet av kunde via online booking",
    createdByStaff: "Opprettet av ansatt",
    statistics: {
      appointmentsThisMonth: "Avtaler denne måneden",
      appointmentsOverall: "Avtaler totalt",
      totalUsedForServicesThisMonth: "Total brukt for tjenester denne måneden",
      totalUsedForServicesOverall: "Total brukt for tjenester totalt",
    },
    payments: "Betalinger",
    newJournal: "Legg til ny journal",
    buttons: {
      cancel: "Avbryt",
      save: "Lagre",
    },
    dialog: {
      unsavedChangesWarning:
        "Du har ulagrede endringer i journalen. Hvis du forlater siden, vil dine endringer gå tapt.",
      exitConfirmation:
        "Du har ulagrede endringer i journalen. Er du sikker på at du vil forlate siden?",
    },
    journal: "Journal",
    generalText: "Generell tekst",
  },
  nameIsRequired: "Navn er påkrevd",
  amountMustBeOneOrHigher: "Beløp må være 1 eller høyere",
  resources: {
    deleteConfirmation: "Er du sikker på at du vil slette denne ressursen?",
    deleteHeader: "Slett Ressurs",
    name: "Navn",
    description: "Beskrivelse",
    quantity: "Antall",
    createResource: "Opprett Ressurs",
    editResource: "Rediger Ressurs",
    nameDescription: "Navn på ressursen",
    descriptionDescription: "Beskrivelse av ressursen",
    quantityDescription: "Antall av ressursen",
  },
  functions: {
    contactUs: "Kontakt Oss",
    customerLogin: "Kundelogin",
    invoicesAndPayments: "Fakturaer og Betalinger",
    customFields: "Egendefinerte Felt",
    newsletter: "Nyhetsbrev",
    giftCards: "Gavekort",
    customerFollowUp: "Kundeoppfølging",
  },
  login: {
    fillEmailPassword: "E-post og passord må fylles ut.",
    passwordsMustMatch: "Passordene må være like.",
    emailInUse: "Denne e-postadressen er allerede i bruk.",
    invalidEmail: "Ugyldig e-postadresse.",
    weakPassword: "Passordet må være minst 6 tegn.",
    userNotFound: "Bruker ikke funnet.",
    wrongEmailPassword: "Feil e-post eller passord.",
    fillEmailForReset: "Vennligst fyll ut e-post for å tilbakestille passord.",
    sendNewPasswordTo: "Send nytt passord til {email}?",
    emailSent: "En e-post er sendt til deg.",
    tryFree: "Prøv gratis i 14 dager",
    repeatPassword: "Gjenta passord",
    register: "Registrer deg",
    haveAccount: "Har du konto?",
    loginHere: "Logg inn her",
    login: "Logg inn",
    password: "Passord",
    forgot: "Glemt?",
    next: "Neste",
    firstName: "Fornavn",
    lastName: "Etternavn",
    phoneNumber: "Telefonnummer",
    phoneNumberRequired: "Telefonnummeret må være 8 sifre.",
    firstNameRequired: "Fornavn er påkrevd.",
    lastNameRequired: "Etternavn er påkrevd.",
  },
  systemsSettings: {
    selectLanguage: "Velg Språk",
    languageDescription:
      "Velg språket for systemet, både for dashbordet og bestillingssiden.",
    language: "Språk",
    timeFormat: "Tidformat",
    timeFormatDescription:
      "Velg tidformatet for systemet, både for dashbordet og bestillingssiden.",
    timeZone: "Tidssone",
    timeZoneDescription:
      "Velg tidssonen for systemet. Kontakt oss for å endre.",
    defaultCountryCode: "Standard landkode",
    defaultCountryCodeDescription:
      "Velg standard landkoden for systemet. Dette vil bli brukt for å sette standard landkoden på bestillingssiden, og for å bestemme landkoden for SMS til personalet.",
  },
  user: {
    updateFailed: "Kunne ikke oppdatere bruker.",
    smsVerificationError: "Kan ikke bruke SMS-verifisering uten et abonnement.",
    bookingLinkExists:
      "Denne bedriftsnavnet eksisterer fra før av. Ditt bedriftsnavn? Kontakt oss.",
    loginError:
      "Noe gikk galt med innlogging. Hvis dette problemet fortsetter, vennligst kontakt oss.",
    imageUploaded: "Bildet er lastet opp.",
    imageUploadError:
      "Bildet er for stort eller feil format. Bruk JPG eller PNG.",
  },
  hideInBooking: "Skjul i booking",
  discount: "Rabatt",
  newPrice: "Ny pris",
  assignToAllStaff: "Tilordne til alle ansatte",
  appointmentSummary: {
    fromOnlineBooking: "Fra online booking",
    assignStaff: "Tilordne ansatte",
    yourNotes: "Dine notater",
  },
  appointmentMenu: {
    moveAppointmentMessage: "Klikk på tid i kalenderen for å flytte avtalen.",
    confirmDeleteAppointment: "Er du sikker på at du vil slette denne avtalen?",
    deleteAppointmentHeader: "Slett Avtale",
    appointmentDeleted: "Avtalen har blitt slettet.",
    edit: "Rediger",
    delete: "Slett",
    findInCalendar: "Søk i Kalender",
  },
  customerModalMessages: {
    customerUpdated: "Kunde oppdatert.",
    customerUpdateFailed: "Kunde oppdatering feilet.",
  },
  multipleServices: "Flere Tjenester",
  emailAddress: "E-postadresse",
  commitChart: {
    currentYear: "Tjent {year}",
    previousYear: "Tjent {year}",
  },
  calendarMessages: {
    moveAppointmentTo: "Flytte tid til",
  },
  appointments: {
    creatingAppointment: "Oppretter avtale...",
    appointmentCreated: "Avtalen har blitt opprettet!",
    overlappingAppointment:
      "Avtalen overlapper med en annen avtale. Vil du opprette avtalen likevel?",
    alreadyBooked:
      "Avtalen du prøver å bestille har allerede blitt bestilt av noen andre.",
    overlappingWithNext:
      "Avtalen du prøver å bestille overlapper med neste avtale. Velg en kortere tid.",
  },
  customerName: "Kundenavn",
  createAppointmentMessage: {
    invalidTimeFormat: "Ugyldig tidformat",
    creationCancelled: "Oppretting av avtale avbrutt.",
    enterCustomerName: "Du må skrive inn et kundenavn.",
    selectService: "Du må velge en tjeneste.",
    editEndTimeWithoutStart: "Prøvde å redigere sluttid uten å sette starttid.",
    createNewCustomer: "Opprett ny kunde for {name}",
    customEvent: "Egenhendelse",
    createAppointment: "Opprett Avtale",
    appointmentCreationFailed:
      "Avtale oppretting feilet. Vær snill kontakt kundeservice.",
  },
  bookingPageGeneralSettings: {
    auditLogs: {
      changedBookingLink: "Endret innstilling 'Booking-ID' til {link}",
      changedCommonLink: "Endret innstilling 'Common-ID' til {link}",
      changedLocationName: "Endret innstilling 'Lokasjon navn' til {name}",
      validation: {
        bookingLinkTooShort: "Booking link må inneholde minst 3 tegn",
      },
    },
    mobileHeader: {
      home: "Hjem",
      calendar: "Kalender",
      customers: "Kunder",
      new: "Ny",
      orders: "Bestillinger",
      settings: "Innstillinger",
      log: "Logg",
      statistics: "Statistikk",
    },
  },
  password: "Passord",
  dialogMessages: {
    confirm: "Bekreft",
    cancel: "Avbryt",
    no: "Nei",
  },
  appointmentList: {
    dialog: {
      delete_appointments: {
        message: "{0} bestillinger vil bli slettet",
        header: "Slett bestillinger?",
      },
      confirmationMessage: "{0} bestillinger er slettet",
    },
    appointment: {
      deleteSelectedAppointments: "Slett valgte bestillinger",
      filter: "Filter",
      showMoreCustomerInfo: "Vis mer kundeinformasjon",
      noAppointments: "Ingen bestillinger",
      loading: "Laster",
      status: {
        ny: "Ny",
        ferdig: "Ferdig",
      },
      select: "Velg",
    },
  },
  $vuetify: {
    badge: "Skilt",
    close: "Lukk",
    dataIterator: {
      noResultsText: "Fant ingen matchende elementer.",
      loadingText: "Laster elementer...",
    },
    dataTable: {
      itemsPerPageText: "Rader per side:",
      ariaLabel: {
        sortDescending: "Sortert synkende.",
        sortAscending: "Sortert stigende.",
        sortNone: "Ikke sortert.",
        activateNone: "Aktiver for å fjerne sortering.",
        activateDescending: "Aktiver for å sortere synkende.",
        activateAscending: "Aktiver for å sortere stigende.",
      },
      sortBy: "Sorter etter",
    },
    dataFooter: {
      itemsPerPageText: "Elementer per side:",
      itemsPerPageAll: "Alle",
      nextPage: "Neste side",
      prevPage: "Forrige side",
      firstPage: "Første side",
      lastPage: "Siste side",
      pageText: "{0}-{1} av {2}",
    },
    datePicker: {
      itemsSelected: "{0} valgt",
      nextMonthAriaLabel: "Neste måned",
      nextYearAriaLabel: "Neste år",
      prevMonthAriaLabel: "Forrige måned",
      prevYearAriaLabel: "Forrige år",
    },
    noDataText: "Ingen data er tilgjengelig",
    carousel: {
      prev: "Forrige bilde",
      next: "Neste bilde",
      ariaLabel: {
        delimiter: "Karusellbilde {0} av {1}",
      },
    },
    calendar: {
      moreEvents: "{0} flere",
    },
    fileInput: {
      counter: "{0} filer",
      counterSize: "{0} filer ({1} totalt)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Paginasjonsnavigasjon",
        next: "Neste side",
        previous: "Forrige side",
        page: "Gå til side {0}",
        currentPage: "Gjeldende side, side {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Rating {0} of {1}",
      },
    },
  },
  serviceCategories: {
    nameRequired: "Navn må oppgis",
    confirmDelete: "Er du sikker på at du vil slette denne kategorien?",
    deleteHeader: "Slett tjeneste",
    editCategory: "Rediger kategori",
    name: "Navn",
    cancel: "Avbryt",
    save: "Lagre",
    categoryName: "Navn på kategori",
    create: "Opprett",
    sort: "Sorter",
    table: {
      name: "Navn",
      sort: "Sorter",
    },
    validation: {
      nameRequired: "Navn på kategorien er påkrevd",
    },
    createCategory: "Opprett kategori",
  },
  bookingPageInformationSettings: {
    auditLogs: {
      changedInformationBoxText: "Endret teksten i informasjonsboksen.",
      changedShowInformationBox:
        "Endret innstilling 'Vis informasjonsboksen' til {setting}",
    },
  },
  serviceOptions: {
    auditLogs: {
      changedShowServicePrice:
        "Endret innstilling 'Vis pris på tjenester' til {setting}",
      changedMultipleServices:
        "Endret innstilling 'Flervalg av tjenester' til {setting}",
    },
  },
  bookingPageFieldSettings: {
    auditLogs: {
      changedMultipleServices: "Endret innstilling 'Kundefelt' til {field}",
    },
  },
  routes: {
    login: "Logg inn",
    login_: "Logg inn", // Note: "login " has a trailing space; adjusted key to "login_"
    Settings: "Innstillinger",
    CompanyInfo: "FirmaInfo",
    SystemSettings: "Systeminnstillinger",
    Services: "Tjenester",
    Categories: "Kategorier",
    Resources: "Ressurser",
    Subscription: "Abonnement",
    Accounts: "Kontoer",
    BookingAlerts: "BestillingsVarsler",
    CustomerInformation: "KundeInformasjon",
    Booking: "Bestilling",
    BookingSide: "BestillingsSide",
    Options: "Alternativer",
    Staff: "Personale",
    CalendarSettings: "Kalenderinnstillinger",
    Newsletter: "Nyhetsbrev",
    AuditLog: "Revisjonslogg",
    ExtraServices: "EkstraTjenester",
    BookingInformation: "BestillingsInformasjon",
    CustomerAccount: "KundeKonto",
    Kunder: "Kunder",
    Kunde: "Kunde",
    Kalender: "Kalender",
    KalenderBestilling: "KalenderBestilling",
    Hjem: "Hjem",
    oAuthCallBack: "OAuthTilbakekalling",
    Onboarding: "Oppstart",
    muscleGroupSelector: "MuskelgruppeVelger",
    Statistikk: "Statistikk",
    Eksporter: "Eksporter",
    Bestillinger: "Bestillinger",
    Bestilling: "Bestilling",
    Faktura: "Faktura",
    Betalinger: "Betalinger",
    Gavekort: "Gavekort",
    Tjenester: "Tjenester",
    Hendelser: "Hendelser",
    Logg: "Logg",
    AdminLogg: "AdminLogg",
    Debug: "Feilsøking",
    Register: "Registrer",
    overview: "Oversikt",
    staff: "Personale",
    StaffWorkingHours: "Arbeidstider",
    StaffGoogleCalendar: "Google Kalender",
    StaffFriday: "Feriedager",
    StaffServices: "Tjenester",
    StaffInformation: "Personaleinformasjon",
    StaffOverview: "Personaleoversikt",
  },
  fieldsettings: {
    hideDaysOutsideWorkingHours: "Skjul dager uten for arbeidstid",
    showServiceNameInEvent: "Vis tjenestenavn i hendelse",
    showTimeInEvent: "Vis tidspunkt i hendelse",
    showStaffInEvent: "Vis ansatt i hendelse",
  },
  statistics: {
    serviceName: "Tjeneste",
    bookings: "Bestillinger",
    customers: "Kunder",
  },
  all: "Alle",
  createService: {
    title: "Opprett Tjeneste",
    assignToAllStaff: "Tilordne til alle ansatte",
    assignToAllStaffDescription:
      "Hvis merket, vil tjenesten bli tilordnet til alle ansatte.",
    discount: "Rabatt",
    discountDescription: "Hvis merket, vil tjenesten ha en rabatt.",
    newPrice: "Ny Pris",
    newPriceDescription: "Den nye prisen til tjenesten.",
    hideInBooking: "Skjul i Bestilling",
    hideInBookingDescription: "Skjul tjenesten i bestillingssiden.",
    serviceName: "Tjenestenavn",
    serviceNameDescription: "Navnet til tjenesten.",
    description: "Beskrivelse",
    descriptionDescription: "Beskrivelsen til tjenesten.",
    price: "Pris",
    priceDescription: "Prisen til tjenesten.",
    duration: "Varighet",
    durationDescription: "Varigheten til tjenesten i minutter.",
    durationForStaff: "Varighet for Personal",
    durationForStaffDescription:
      "Varigheten i minutter til tjenesten for at personal skal kunne fullføre den.",
    durationForCustomer: "Varighet for Kunde",
    durationForCustomerDescription:
      "Varigheten i minutter til tjenesten som vises til kundene.",
    color: "Farge",
    colorDescription: "Fargen til tjenesten i kalenderen.",
    showCustomColor: "Vis egendefinert farge",
    showCustomColorDescription:
      "Hvis merket, vil tjenesten ha en egendefinert farge.",
    customColor: "Egendefinert farge",
    customColorDescription: "Egendefinert fargen til tjenesten.",
    discountPrice: "Rabattert pris",
    discountPriceDescription: "Den rabatterte prisen til tjenesten.",
    discountPriceChange: "Rabattert pris endret",
    discountPriceChangeDescription:
      "Den rabatterte prisen til tjenesten har blitt endret.",
    validation: {
      priceRequired: "Pris er påkrevd",
      durationRequired: "Varighet er påkrevd",
      serviceNameRequired: "Tjenestenavn er påkrevd",
      descriptionRequired: "Beskrivelse er påkrevd",
      durationMustBeNumber: "Varighet må være et tall",
      priceMustBeNumber: "Pris må være et tall",
      durationPositive: "Varighet må være positiv",
      durationExceedsLimit: "Varighet overstiger grense",
      invalidDiscountPrice: "Ugyldig rabattert pris",
      discountPriceMustBeNumber: "Rabattert pris må være et tall",
      priceNotNegative: "Pris må være positiv",
      discountPriceNotNegative: "Rabattert pris må være positiv",
    },
  },
  validation: {
    nameRequired: "Navn er påkrevd",
  },
  writeHere: "Skriv her...",
  registerFirebase: {
    email: "E-post",
    password: "Passord",
    passwordAgain: "Passord igjen",
    register: "Registrer",
    emailAlreadyInUse: "E-post allerede i bruk",
    invalidEmail: "Ugyldig e-post",
    passwordTooWeak: "Passord for svakt",
    emailAndPasswordRequired: "Email og passord er påkrevd",
    passwordsMustMatch: "Passordene må være like",
  },
  placeHolder: {
    typeToSearch: "Skriv for å søke",
  },
  selectDate: "Velg dato",
};
